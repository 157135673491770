import {
  MENU_LIST_LOADING,
  GET_MENU_LIST_ERROR,
  GET_MENU_LIST,
  GET_MENU_BY_ID_LOADING,
  GET_MENU_BY_ID_SUCCESS,
  GET_MENU_BY_ID_ERROR,
  DELETEING_MENU,
  DELETE_MENU,
  DELETE_MENU_ERROR,
  DELETED_MENU_LIST_LOADING,
  GET_DELETED_MENU_LIST,
  GET_DELETED_MENU_LIST_ERROR,
  PERMA_DELETEING_MENU,
  PERMA_DELETE_MENU,
  PERMA_DELETE_MENU_ERROR,
  RESTORE_MENU_LIST_LOADING,
  RESTORE_MENU_LIST,
  RESTORE_MENU_LIST_ERROR,  
  GET_MENU_OPTIONS_LOADING,
  GET_MENU_OPTIONS_SUCCESS,
  GET_MENU_OPTIONS_ERROR,
  SORTING_MENU,
  SORT_MENU,
  SORT_MENU_ERROR
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  options:undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MENU_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_MENU_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_MENU:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_MENU_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_MENU:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_MENU_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
      case GET_MENU_OPTIONS_LOADING:
        return {
          ...state,
          loading: true,
          error: false,
        };

        case SORTING_MENU:
          return {
            ...state,
            loading: true,
            error: false,
          };

    case GET_MENU_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
      case SORT_MENU:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
          error: false,
        };
    case GET_MENU_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case GET_DELETED_MENU_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case DELETE_MENU:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_MENU:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case RESTORE_MENU_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

      case GET_MENU_OPTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          options: action.payload.data,
          error: false,
        };

    case GET_MENU_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_MENU_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_MENU_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_MENU_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_MENU_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_MENU_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

      case GET_MENU_OPTIONS_ERROR:
        return {
          ...state,
          options: undefined,
          loading: false,
          error: action.payload.data
        };

        case SORT_MENU_ERROR:
          return {
            ...state,
            data: undefined,
            loading: false,
            error: action.payload.data
          };

    default:
      return state;
  }
};

export default reducer;