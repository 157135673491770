import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = (props) => {
  const editorConfig = {
    ...(props.simpleTollbar
      ? {
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "H2 H3 | " +
            "bold italic | bullist numlist | " +
            "removeformat link",
          
        }
      : {
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | image media | code",
        }),
  };
  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TinyMCE_apiKey}
        value = {props.value}
        initialValue={props.initialValue?props.initialValue:null}
        init={{ ...editorConfig, ...(props.inline &&{
            inline: true
        }), ...(props.placeholder && {placeholder: props.placeholder}) }}
        onEditorChange={props.onChange}
      />
    </>
  );
};

export default TextEditor;
