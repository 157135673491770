import {
  CREATING_ITINERARY,
  UPDATING_ITINERARY,
  ITINERARY_CREATED,
  ITINERARY_UPDATED,
  ERROR_ITINERARY_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_ITINERARY,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.itineraries) {
      let itineraryDay = data.itineraries.map((item) => item.itinerary_day);
      let itineraryTitle = data.itineraries.map((item) => item.itinerary_title);
      let itineraryDescription = data.itineraries.map(
        (item) => item.itinerary_description
      );
      // let itineraryFactsTitles = data.itineraries.map((item) =>
      //   item.facts.map((a) => a.fact_title)
      // );
      // let itineraryFactsValues = data.itineraries.map((item) =>
      //   item.facts.map((a) => a.fact_value)
      // );
      for (let i = 0; i < itineraryDay.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_day]`,
          itineraryDay[i]
        );
      }
      for (let i = 0; i < itineraryTitle.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_title]`,
          itineraryTitle[i]
        );
      }
      for (let i = 0; i < itineraryDescription.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_description]`,
          itineraryDescription[i]
        );
      }
      // for (let i = 0; i < itineraryFactsTitles.length; i++) {
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][fact_title]`,
      //     itineraryFactsTitles[i]
      //   );
      // }
      // for (let i = 0; i < itineraryFactsValues.length; i++) {
      //   webKitFormData.append(
      //     `itineraries[${i}][facts][${i}][fact_value]`,
      //     itineraryFactsValues[i]
      //   );
      // }
    }
    ["itineraries"].forEach((e) => delete data[e]);
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/travel/itinerary/add`, webKitFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: ITINERARY_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/itineraries");
        }
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_ITINERARY_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_ITINERARY,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.itineraries) {
      let itineraryDay = data.itineraries.map((item) => item.itinerary_day);
      let itineraryTitle = data.itineraries.map((item) => item.itinerary_title);
      let ID = data.itineraries.map((item) => item.id);
      let itineraryDescription = data.itineraries.map(
        (item) => item.itinerary_description
      );
      for (let i = 0; i < itineraryDay.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_day]`,
          itineraryDay[i]
        );
      }
      for (let i = 0; i < itineraryTitle.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_title]`,
          itineraryTitle[i]
        );
      }
      for (let i = 0; i < ID.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][id]`,
          ID[i]
        );
      }
      for (let i = 0; i < itineraryDescription.length; i++) {
        webKitFormData.append(
          `itineraries[${i}][itinerary_description]`,
          itineraryDescription[i]
        );
      }
    }
    ["itineraries"].forEach((e) => delete data[e]);
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(
        `${BASE_URL}api/v1/admin/travel/itinerary/edit/${id}`,
        webKitFormData,
        {
          headers: {
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: ITINERARY_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_ITINERARY_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };
