
import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Page/actions";
import clean  from "lodash-clean";

function List({ useAuth, getList, list, onDelete}) {
  const tableConfig = {
    title: "Articles",
    filterOptionTitle: "Team Type",
    filterOptions: [
      "package",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Title", selector: 'page_title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'title',
    searchByColumn: 'title',
    updatePath: "/article/update", 
    addPath: "/article/add",
    noDataMessage: "No Article exists",
    updateLinkColumn: 'page_title',
    deleteBtnText: 'Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
  }

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={clean(list)}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.article,  
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
