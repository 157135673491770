import {
  MENU_LIST_LOADING,
  GET_MENU_LIST,
  GET_MENU_LIST_ERROR,
  GET_MENU_BY_ID_LOADING,
  GET_MENU_BY_ID_SUCCESS,
  GET_MENU_BY_ID_ERROR,
  DELETEING_MENU,
  DELETE_MENU,
  DELETE_MENU_ERROR,
  DELETED_MENU_LIST_LOADING,
  GET_DELETED_MENU_LIST,
  GET_DELETED_MENU_LIST_ERROR,
  PERMA_DELETEING_MENU,
  PERMA_DELETE_MENU,
  PERMA_DELETE_MENU_ERROR,
  RESTORE_MENU_LIST_LOADING,
  RESTORE_MENU_LIST,
  RESTORE_MENU_LIST_ERROR,  
  GET_MENU_OPTIONS_LOADING,
  GET_MENU_OPTIONS_SUCCESS,
  GET_MENU_OPTIONS_ERROR,    
  SORTING_MENU,
  SORT_MENU,
  SORT_MENU_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";


export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: MENU_LIST_LOADING,
  });

  await axios
    .get(`${BASE_URL}api/v1/admin/menu/active`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_MENU_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_MENU_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getMenuOptions = (tokenData) => async (dispatch) => {
  dispatch({
    type: GET_MENU_OPTIONS_LOADING,
  });

  await axios
    .get(`${BASE_URL}api/v1/admin/menu/options`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_MENU_OPTIONS_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_MENU_OPTIONS_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_MENU_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/active/${id}`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_MENU_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_MENU_BY_ID_ERROR,
        payload: error.response,
      });
    });
};

export const getDeletedList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DELETED_MENU_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/deleted`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_DELETED_MENU_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DELETED_MENU_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_MENU,
  });
  let bulk = { bulk: idList };
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/bulk/delete`, bulk, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: DELETE_MENU,
        payload: response,
      });
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_MENU_ERROR,
        payload: error.response,
      });
      message.error(error.response.data.message);
    });
};

export const permanentDeleteEntity = (idList) => async (dispatch) => {
  dispatch({
    type: PERMA_DELETEING_MENU,
  });
  let bulk = { bulk: idList };
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/bulk/perma-delete`, bulk, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: PERMA_DELETE_MENU,
        payload: response,
      });
      message.success(response.data.message);
    })
    .catch(function (error) {
      console.log("error:::::::", error);
      dispatch({
        type: PERMA_DELETE_MENU_ERROR,
        payload: error.response,
      });
      message.error(error.response.data.message);
    });
};

export const restoretDeleteEntity = (idList) => async (dispatch) => {
  dispatch({
    type: RESTORE_MENU_LIST_LOADING,
  });
  let bulk = { bulk: idList };
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/bulk/restore`, bulk, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: RESTORE_MENU_LIST,
        payload: response,
      });
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: RESTORE_MENU_LIST_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};


export const sortEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: SORTING_MENU,
  });  
 
  let webKitFormData = new FormData();
  if (idList.item_array) {
    for (let i = 0; i < idList.item_array.length; i++) {
      webKitFormData.append("item_array[]", idList.item_array[i]);
    }
  }["item_array"].forEach(
    (e) => delete idList[e]
  );
  Object.keys(idList).forEach((item, i) => {
    webKitFormData.append(item, idList[item]);
  });
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/order`, webKitFormData, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: SORT_MENU,
        payload: response,
      });
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: SORT_MENU_ERROR,
        payload: error.response,
      });
      message.error(error.response.data.message);
    });
};